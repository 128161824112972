import React from "react";
import styled from "styled-components";

const StyledOrderedList = styled.ol`
  margin-top: 20px;
  color: var(--primary-text-color);
  line-height: 1.5;
  margin-left: 30px;
  img {
    margin-top: 10px;
  }
`;

const OrderedList = ({children}) =>{
    return <StyledOrderedList>
        {children}
    </StyledOrderedList>
}

export default OrderedList;