import React from "react";
import styled from "styled-components";
import Paragraph from "../components/blog/Paragraph";
import BlogTitle from "../components/blog/BlogTitle";
import ImageWithCaption from "../components/blog/ImageWithCaption";
import BlogHeading2 from "../components/blog/BlogHeading2";
import InternalLink from "../components/blog/InternalLink";
import BlockQuote from "../components/blog/BlockQuote";
import OrderedList from "../components/blog/OrderedList";
import OrderedListItem from "../components/blog/OrderedListItem";
import UnorderedList from "../components/blog/UnorderedList";
import UnorderedListItem from "../components/blog/UnorderedListItem";
import ExternalLink from "../components/blog/ExternalLink";
import {StaticImage} from "gatsby-plugin-image";
import SEO from "../components/SEO";

const BlogPostWrapper = styled.main`
  padding: var(--main-padding-mob);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledMainContent = styled.div`  
  max-width: 750px; 
`;

const HeroSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BlogPost = ({location}) =>{
    return <BlogPostWrapper>
        <StyledMainContent>
            <SEO
                title="5 Practical Tips On How To Organize Your Grocery Shopping List"
                description="Let’s talk about how to make an organized grocery shopping list and save you from an insane number of headaches in the long run."
                location={location}
                pageType="article"
            />

            <HeroSection>
                <BlogTitle>
                    5 Practical Tips On How To Organize Your Grocery Shopping List
                </BlogTitle>
                <ImageWithCaption>
                    <StaticImage src="../assets/images/blogs/5-Practical-Tips-On-How-To-Organize-Your-Grocery-Shopping-List.png" alt="5 Practical Tips On How To Organize Your Grocery Shopping List"/>
                    <em>Photo by <ExternalLink to="https://unsplash.com/@socialtyvr?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Tara Clark</ExternalLink> on <ExternalLink to="https://unsplash.com/s/photos/grocery-shopping?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</ExternalLink></em>
                </ImageWithCaption>
            </HeroSection>
            <Paragraph>
                Let’s face it, rare are the people for whom grocery shopping is a good time. It requires knowing what you’re going to cook in advance (probably for the whole week), what ingredients you need to buy vs. what you already have in the fridge and pantry... Then scheduling the right moment to go out, getting ready, driving, hoping you’ll find parking space in front of the store...
            </Paragraph>
            <Paragraph>
                It’s a hassle. Between work, family, house, and self-care if you manage to squeeze it in (we’re so proud of you if you do), who has the time or energy to waste on grocery shopping?
            </Paragraph>
            <Paragraph>
                That’s why a neat little list will do the trick. A list that is properly organized, that has everything you need for the foreseeable future, and that will get you in and out of the grocery shop in record time.
            </Paragraph>
            <Paragraph>
                Let’s talk about how to make an organized grocery list and save you from an insane number of headaches in the long run.
            </Paragraph>
            <BlogHeading2>
                Don’t wait until the last moment to make your list
            </BlogHeading2>
            <Paragraph>
                Picture this: throughout the week, you notice you’re out of milk. You need more toilet paper. The cat ate the last of its salmon-flavored wet food. You make mental notes to write it all down when it’s time to go shopping.
            </Paragraph>
            <Paragraph>
                But when you finally sit down to create your shopping list, you draw a blank. No thoughts. Head empty.
            </Paragraph>
            <Paragraph>
                It happens to the best of us.
            </Paragraph>
            <Paragraph>
                To avoid this unpleasant situation and to be absolutely sure you’ve put down everything you need to buy, it’s best to keep a running list. Make a note (a real one, not a mental one) of every single thing you think of to buy the moment you think of it.
            </Paragraph>
            <Paragraph>
                Keeping a small notebook or notepad in the kitchen - on the counter or near the fridge - is a good option.
            </Paragraph>
            <Paragraph>
                (But what if you’re not <em>in</em> the kitchen when an item you need to buy crosses your mind?)
            </Paragraph>
            <Paragraph>
                Typing things into the Notes section on your phone is also doable.
            </Paragraph>
            <Paragraph>
                (But that’s not practical to reorganize and might require a re-type of the entire list once you find a moment of free time.)
            </Paragraph>
            <Paragraph>
                From our experience, having a grocery shopping list app on your phone is an ideal solution. You always have your phone with you - even in the bathroom, let's be real - and plenty of apps (ours included) come with neat features that help you reorganize your lists in a blink of an eye.
            </Paragraph>
            <BlockQuote>
                <InternalLink to="/#download-section">Going Shopping</InternalLink> allows you to tag your lists and items for easy sorting, color code them for visual recognition, <em>and</em> reorder them any way you want.
            </BlockQuote>
            <Paragraph>
                So keep adding to your shopping list throughout the week. That way, you will be at least 95% sure you’ve written down everything you’ll need before you set off on your grocery trip.
            </Paragraph>
            <BlogHeading2>
                Make a meal plan
            </BlogHeading2>
            <Paragraph>
                Okay, this one is a bit tricky. Planning weekly meals (or even monthly meals??) can be difficult for those who have no experience with it whatsoever.
            </Paragraph>
            <Paragraph>
                But it is undeniably one of the most effective ways of streamlining your shopping list and saving time and energy on grocery runs.
            </Paragraph>
            <Paragraph>
                Here is a brief overview of how to make a meal plan, step by step:
            </Paragraph>
            <OrderedList>
                <OrderedListItem>
                    Consider the meals you have day in and day out - what you eat for breakfast, lunch, and dinner, and what you snack on in between. Write them all down so you have a clearer picture of what meals you have on rotation.
                </OrderedListItem>
                <OrderedListItem>
                    Try to plan out which of those meals you’ll eat in the following week and at which time of day. For example, let’s say that you want to eat an egg omelet for breakfast on Monday. On Wednesday, you’d like a poached egg on avocado toast, while Saturday is reserved for pancakes. By making this plan (however rough it may be), you should already have a better idea of how many eggs you need for the week.
                </OrderedListItem>
                <OrderedListItem>
                    Download and print out our seven-day meal plan table:
                    <ImageWithCaption>
                        <StaticImage src="../assets/images/blogs/post-2022-1-weekly-meal-plan.png" alt="Meal plan table example" style={{maxWidth:"500px"}}/>
                    </ImageWithCaption>
                </OrderedListItem>
                <OrderedListItem>
                    Write down the meals you chose into their corresponding field in the table.
                </OrderedListItem>
                <OrderedListItem>
                    Go through each and every meal and check whether you have the ingredients necessary to make it.
                </OrderedListItem>
                <OrderedListItem>
                    If you don’t have it, add the missing ingredient to your shopping list. If you already have that ingredient in the meal, increase its quantity to accommodate the new meal.
                </OrderedListItem>
            </OrderedList>
            <Paragraph>And <i>voila</i>! A meal plan <em>and</em> a shopping list all mapped out!</Paragraph>
            <BlogHeading2>Organize list by aisle</BlogHeading2>
            <Paragraph>
                One of the most time-saving ways you can rearrange grocery items on your list is by the way the store is laid out.
            </Paragraph>
            <Paragraph>
                If you’re already familiar with the layout of your local grocery store, you shouldn’t have too much trouble picturing how the aisles and different product categories are arranged in the space. Fresh produce goes here, dairy products are all the way in the back, household items are in the middle...
            </Paragraph>
            <Paragraph>
                Work your way from the entrance to the store to the cash register. By grouping your items according to aisles, you’ll spend a lot less time running back and forth between shelves.
            </Paragraph>
            <Paragraph>
                However, if you intend to visit a new store and have no idea how it’s laid out, don’t despair!
            </Paragraph>
            <Paragraph>
                Simply arrange your list by products that are typically grouped together in stores. Here is a short list of the most commonly found grocery store product categories:
            </Paragraph>
            <UnorderedList>
                <UnorderedListItem>Fresh produce</UnorderedListItem>
                <UnorderedListItem>Meat and seafood</UnorderedListItem>
                <UnorderedListItem>Dairy</UnorderedListItem>
                <UnorderedListItem>Deli</UnorderedListItem>
                <UnorderedListItem>Bakery</UnorderedListItem>
                <UnorderedListItem>Frozen goods section</UnorderedListItem>
                <UnorderedListItem>Pet food</UnorderedListItem>
                <UnorderedListItem>Beauty products</UnorderedListItem>
                <UnorderedListItem>Health products</UnorderedListItem>
                <UnorderedListItem>Household goods</UnorderedListItem>
            </UnorderedList>
            <Paragraph>
                Organizing your list by these categories should be a good start before you get to know the store a bit better.
            </Paragraph>
            <BlogHeading2>Organize list by price</BlogHeading2>
            <Paragraph>
                Alternatively, if you’re on a budget, you can organize your food items by price. The items at the top of your list should be the least expensive ones, those that you can buy in bulk, as well as the most versatile items (that you can use in many different ways, such as eggs, canned tomatoes, and similar).
            </Paragraph>
            <Paragraph>
                The catch here is that you have to know the cost of each item you want to buy <em>before</em> you get to the store. One way to do this is to save old receipts and/or transcribe the prices from them into a notebook or your phone notes app.
            </Paragraph>
            <Paragraph>
                Another, way more convenient way, is to use a grocery list app that allows you to:
            </Paragraph>
            <OrderedList>
                <OrderedListItem>Enter the price of each item as you shop, and</OrderedListItem>
                <OrderedListItem>Save those items so that you don’t have to add the price for the same item over and over again in the future.</OrderedListItem>
            </OrderedList>
            <BlockQuote>
                We added the price feature into <InternalLink to="/#download-section">Going Shopping</InternalLink> for the exact reason of wanting to keep track of our grocery spending. Works wonders for optimizing your grocery budget!
            </BlockQuote>
            <BlogHeading2>
                Save your shopping list for next time
            </BlogHeading2>
            <Paragraph>
                Finally, it would be best to not delete or throw away your shopping list the moment you’re done with shopping. Why?
            </Paragraph>
            <Paragraph>
                The previous shopping lists are an excellent starting point for your future ones. (Especially if you don’t feel like making a meal plan.)
            </Paragraph>
            <Paragraph>
                When making a new list, if at any point you feel like you’re stuck, you can just go back to the old ones and get inspiration from them. It is also a good idea to skim through your previous list(s) in case you forgot a staple grocery item you need regardless of any meals you have planned.
            </Paragraph>
            <BlogHeading2>Bottom line: It’s all about planning</BlogHeading2>
            <Paragraph>
                There’s no beating around it: if you want to be more organized while grocery shopping, you need to put in some effort. Scheduling weekly (or biweekly or monthly) shopping trips, keeping track of what you need on a daily basis, and organizing your lists by aisle or by budget will do wonders to ease the stress and frustration of grocery shopping.
            </Paragraph>
            <Paragraph>
                And if you start meal planning as well? Woo, there’ll be no stopping you!
            </Paragraph>
            <Paragraph>
                Hopefully, our list of practical <InternalLink to="/grocery-shopping-tips/">grocery shopping tips</InternalLink> on how to organize your shopping lists gives you inspiration and motivation to start implementing them into your routine.
            </Paragraph>
            <Paragraph>
                And don’t forget to download our <InternalLink to="/#download-section">free shopping list app</InternalLink> so you can apply all of our advice today!
            </Paragraph>
        </StyledMainContent>
    </BlogPostWrapper>

}



export default BlogPost;