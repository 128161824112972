import React from "react";
import styled from "styled-components";

const StyledOrderedListItem = styled.li`
  margin-top: 10px;
`;


const OrderedListItem = ({children}) =>{
    return <StyledOrderedListItem>
        {children}
    </StyledOrderedListItem>
}

export default OrderedListItem;